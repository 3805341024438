<script>
	import CityfloLocationSelectBox from './CityfloLocationSelectBox.svelte';
	import { createEventDispatcher } from 'svelte';
	import {
		dropOffFieldData,
		formInFocus,
		loading,
		pickUpFieldData,
		sectionIntroDelay,
		routeStops,
		activeCity,
	} from './store';
	import { isBusSearch } from '../store';
	import { scale } from 'svelte/transition';
	import SwapSVG from '~/icons/shuffle.svelte';
	import { isMobile } from '~/utils/store';

	export let mainForm = false;

	let toggleSwapIconPosition = false;

	const dispatch = createEventDispatcher();

	function handleSwapLogic() {
		toggleSwapIconPosition = !toggleSwapIconPosition;

		let tempSelectedPlaceDetails = $pickUpFieldData;
		$pickUpFieldData = $dropOffFieldData;
		$dropOffFieldData = tempSelectedPlaceDetails;

		$formInFocus = true;

		if (!mainForm && !!$pickUpFieldData.placesDetails && !!$dropOffFieldData.placesDetails) {
			dispatch('swap');
		}
	}

	$: routeMapPageLink = `/routes/${$activeCity === 1 ? 'mumbai' : 'hyderabad'}/`;
</script>

<div class:pt-10={$isBusSearch} class="h-fit place-self-center w-full md:pt-10">
	<slot name="section-header" />
	<form
		id="find-my-bus-form"
		on:submit|preventDefault
		class="grid grid-rows-[auto_auto_auto] gap-y-4 md:col-start-1 md:row-start-2"
	>
		<fieldset class="contents">
			<slot name="form-header" />
			<div class="relative">
				<CityfloLocationSelectBox
					errorMessage="Pick-up address is required!"
					inputStyleClass={`text-primary  focus:text-primary
					${$isBusSearch ? 'h-[60px] pt-4 md:pt-8' : 'pt-8'}`}
					initialLabel="Enter exact pickup location"
					label="Pickup Location"
					name="pickup"
					placeHolderStyleClass="focus:placeholder:text-[#BBBBBB]"
					placeholder={$pickUpFieldData.placeholder}
					tabindex={mainForm ? '0' : '-1'}
					type="text"
				/>
				<button
					id="search-form-button-swap"
					class={`cursor-pointer scale-75 shadow-[0px_0px_14px_rgba(120,_92,_31,_0.2)] md:scale-100 absolute right-5 -bottom-10 z-10 inline-block rounded-full p-4 bg-accent transition-transform duration-150 ease-out ${
						mainForm
							? 'shadow-[0px_0px_14px_rgba(120,_92,_31,_0.2)]'
							: 'search shadow-[0px_0px_6px_rgba(120,_92,_31,_0.2)]'
					}`}
					class:rotate-[180deg]={toggleSwapIconPosition}
					on:click={handleSwapLogic}
					tabindex="-1"
					type="submit"
				>
					<SwapSVG class="w-6 h-6 pointer-events-none" />
				</button>
			</div>
			<CityfloLocationSelectBox
				errorMessage="Drop-off address is required!"
				inputStyleClass={`text-primary  focus:text-primary
					${$isBusSearch ? 'h-[60px] pt-4 md:pt-8' : 'pt-8'}`}
				initialLabel="Enter exact dropoff location"
				label="Dropoff Location"
				name="drop"
				placeHolderStyleClass="focus:placeholder:text-[#BBBBBB]"
				placeholder={$dropOffFieldData.placeholder}
				tabindex={mainForm ? '0' : '-1'}
				type="text"
			/>
		</fieldset>
		{#if ($isBusSearch || $formInFocus || $routeStops?.length == 0 || !$routeStops) && !$loading}
			<div
				class:pb-4={$isMobile && !$isBusSearch}
				class:pt-4={$isMobile && $isBusSearch}
				class={`grid gap-6 md:mt-4 ${mainForm ? 'mt-4' : ''} ${$isMobile ? 'grid-cols-1' : 'grid-cols-2'}`}
			>
				<button
					id="search-form-button-submit"
					in:scale={{ delay: sectionIntroDelay - 250, duration: mainForm ? 0 : 150 }}
					type="submit"
					class="btn btn-primary search"
					><span class="pointer-events-none" in:scale={{ delay: sectionIntroDelay, start: 1 }}
						>{mainForm ? 'Search for buses' : 'Search'}</span
					></button
				>
				<!-- Display route map link on home page mobile form -->
				<!-- {#if mainForm && $isMobile}
					<a
						class="font-bold text-accent border-b-2 border-accent w-fit h-fit place-self-center"
						target="city"
						href={`route-map/${$activeCity === 1 ? 'mumbai' : 'hyderabad'}/`}>{$activeCity === 1 ? 'Mumbai Route Map' : 'Hyderabad Route Map'}</a
					>
				{/if} -->
			</div>
		{/if}

		{#if !$isBusSearch && $isMobile}
			<a class="text-center underline underline-offset-2 text-primary font-bold" href={routeMapPageLink}
				>View all available routes</a
			>
		{/if}
	</form>
</div>
