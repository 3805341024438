<script>
	import { Splide, SplideSlide } from '@splidejs/svelte-splide';
	import { routeStops } from '../../store';

	// import '@splidejs/svelte-splide/css/core';
	import '@splidejs/svelte-splide/css';
	import RouteTimingCard from './RouteTimingCard.svelte';
	import { isMobile } from '~/utils/store';
</script>

{#if $isMobile !== undefined}
	<Splide
		options={{
			fixedWidth: $isMobile ? '90%' : '350px',
			gap: $isMobile ? '1em' : '2em',
			focus: $isMobile && 'center',
			pagination: false,
			rewindByDrag: true,
			padding: $isMobile ? { left: '5%', right: '5%' } : { right: '70%' },
			type: 'slide',
			width: '100%',

			arrows: false,
		}}
	>
		{#each $routeStops as routeStop}
			<SplideSlide class="rounded-2xl  !my-2">
				<RouteTimingCard routeDate={routeStop} page="home" />
			</SplideSlide>
		{/each}
	</Splide>
{/if}
